@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: text;
}

a {
  text-decoration: none;
  color: unset;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar {
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0b5ad7;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2563eb;
}
.left-aligned-cell {
  text-align: left; /* Left-align data cells */
}

.right-aligned-header {
  text-align: right; /* Right-align header cells */
}

/* CSS */
.even-row {
  background-color: #f2f2f2;
  /* Gray */
}

.odd-row {
  background-color: #ffffff;
  /* White */
}